.order-tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    border-bottom: 2px solid #CACACA;
}

.order-tabs button {
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    color:#657A8D;
    font-weight: 600;
}

.order-tabs .active {
    color:#324E68;
    font-weight: 700;
    border-bottom: 3px solid#324E68;
}