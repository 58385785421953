@import url(../fonts/stylesheet.css);
@import url(../fonts/calibri/stylesheet_two.css);
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;700&display=swap');




:root {
    --blue: #1383F4;
    --white: #ffffff;
    --theme-text: #324C64;
    --theme-text-two:#324E68;
    --gray:#657A8D;
    --theme-border: 1px solid #EAEDF2;;
    --bd-radius:8px;
    --bd-radius-two:5px;
    --form-bd:1px solid #1383F4;
    --header-color:#396EA0;
    --theme-shadow:0px 2px 4px #00000040;
    --theme-form-bd: 1px solid #EAEDF2;
    --theme-form-rds: 5px;
  }
  :focus{
      box-shadow: none !important;
  }
  .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: none;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow:none;
}
.valid_info{
    margin: 0;
    color: red;
    text-align: left;
}
a{
    text-decoration: none;
}
.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: none;
}
a:hover{
    text-decoration: none;
}
*{
    box-sizing: border-box;
}

body{
    margin: 0;
    padding: 0px;
    font-family: 'Calibri';
    font-style: normal;
    font-display: swap;
   
}

header{
    padding: 20px 0;
}

.head_heading{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-align: center;
    color:var(--header-color);
    margin: 0;
}

.home_heading{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--theme-text);
    margin: 10px 0 0 0 ;
}

/* Booking_page_start */
.up_booking{
    padding: 10px 15px 15px 15px;
    box-shadow: var(--theme-shadow);
    border-radius: var(--bd-radius);
    margin-top: 10px;
}


.up_booking h5{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--theme-text);
    margin: 0;
    padding-top: 5px;
}

.up_booking h5 span{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: var(--gray);
    padding-left: 5px;
}

.up_booking p{
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    margin: 0;
    color: var(--theme-text);
}

.up_booking ul{
    margin: 0;
    list-style: none;
    padding: 0;
    margin-top: 10px;
}

.up_booking ul li{
    display: inline-block;
    background: var(--blue);
    border-radius: var(--bd-radius-two);
    text-align: center;
    width: 35px;
    height: 35px;
    margin-left: 17px;
    padding: 4px;
    line-height: 0;
    color: var(--white) ;
}

.up_booking ul li:first-child{
    margin-left: 0;
}

.up_booking ul li a{
    color: var(--white);
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
}

.up_booking .media img{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-top: 10px;
}

.gps_switch{
    padding-top:15px;
}
.gps_switch input[type='checkbox']{
    width: 0;
    height: 0;
}

.gps_switch .gps_text{
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--theme-text);
    margin: 0;
    padding-right: 10px;
}

.gps_switch .gps_text::after{
    display: none;
}
.gps_switch .gps_text::before{
    display: none;
}
.custom-control-label::before{
    border-color:#3B8D49 ;
}
.custom-switch .custom-control-label::after{
    background-color:#3B8D49 ;
}

.custom-control-input:checked~.custom-control-label::before{
    background-color:#3B8D49 ;
    border-color:#3B8D49 ;
}

.box_cat{
    border-radius: var(--bd-radius);
    box-shadow: var(--theme-shadow);
    padding: 10px;
    text-align: center;
    min-height: 95px;
    width: 44%;
    margin-left: 4%;
    float: left;
    margin-top: 16px;
}

.box_cat_new{
    border: var(--theme-border);
    box-shadow: none;
}

.box_cat img{
    width: 50px;
    height: 50px;
    object-fit: scale-down;
}

.box_cat h3{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: capitalize;
    color: var(--theme-text);
    margin: 0;
    padding-top: 7px;
}

/* Footer_first_fixed */
.Foot_btn_first{
    position: fixed;
    bottom: 10px;
    width: 100%;
}

.Foot_btn_first .close_btn{
    display: none;
}

.btn-theme{
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    background: var(--blue);
    color: var(--white) !important;
    padding: 16px 0;
    text-align: center;
    width: 100%;
    box-shadow: 0px 2px 5px #00000040;
    border-radius: 12px;
    border: 0;
}

.btn-link{
    font-size: 20px;
    line-height: 22px;
    color: var(--blue)
}

.btn-theme img{
    padding-left: 12px;
}

/* Booking_page_end */

/* login_page */
.login_page{
    width: 100%;
    /* display: grid;
    place-items: center; */
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -65%);
}

.login_page h3{
    font-weight: 400;
    font-size: 32px;
    line-height: 22px;
    text-align: center;
    color: var(--theme-text);
    margin-bottom: 16px;
}
.login_page .btn-theme{
    margin-top:20px;
}

input[type='text']{
    background-color: var(--white);
    border-radius: var(--theme-form-rds);
    border: var(--theme-form-bd);
    color: #8E8E8E !important;
    height: 40px;
    font-size: 16px;
    line-height: 26px;
    padding-left: 20px;
}

.login_page p{
    margin-top: 30px;
    color:#000000 ;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}
.login_page  .btn-link{
    padding: 0;
    font-size: 16px;
}

/* signup_page */
.signup_page{
    padding-top: 70px;
}

.signup_page p{
    margin-top: 30px;
    color:#000000 ;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

.signup_page  .btn-link{
    padding: 0;
    font-size: 16px;
}

.btn-theme_lang{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    background: var(--blue);
    box-shadow: var(--theme-shadow);
    border-radius: 2px;
    padding: 9px 0px;
    color: var(--white) !important;
    width: 88px;
    margin-bottom: 20px;
    
}
.btn_hindi_lang{
    background: rgba(234, 237, 242, 0.5);
    box-shadow: var(--theme-shadow);
    border-radius: 2px;
    color: var(--theme-text) !important;
}

.signup_page h3{
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--theme-text);
    margin-bottom: 10px;
}

label{
    font-size: 18px;
    line-height: 22px;
    margin-bottom:5px;
    color: var(--theme-text);
}

.signup_page .btn-theme{
    margin-top: 40px;
}
input[type=radio] {
    border: 0px;
    width: 22px;
    height: 22px;
}

.form-check-label {
    margin-bottom: 0;
    padding-left: 10px;
    padding-top: 5px;
}

.form-group {
    margin-bottom: 20px;
}

.otp_sec{
    padding-top: 80px;
}

.otp_sec h1{
    font-weight: 400;
    font-size: 32px;
    line-height: 22px;
    color: var(--theme-text);
    text-align: center;
    margin-bottom: 8px;
}

.otp_sec p{
    color:var(--gray);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin: 0;
}
.otp_here{
    width: 80%;
    margin:  30px auto;
}

.otp_here .col-3{
 padding-right: 10px;
 padding-left: 10px;
}

#verifyOTPForm input[type='number']{
    text-align: center;
    width: 100%;
}

.otp_here .col-3 input[type='text']{
    height: 36px;
    text-align: center;
    width: 100%;
    padding: 0;
}

.timerText{
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--theme-text);
}

.otp_sec .btn-link{
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 0;
    color: var(--blue) !important;
}
.otp_count{
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    color: #010101 !important;
    text-align: left !important;
    padding-top: 30px;
}

.box_cat_two{
    width: 28%;
    min-height: 77px;
    border: var(--theme-border);
    border-radius: var(--bd-radius);
    padding: 10px;
    text-align: center;
    margin-left: 4%;
    float: left;
    margin-top: 16px;
    /* height: 77px; */
}

.box_cat_two img{
    width: 35px;
    height: 35px;
    object-fit: contain;
}

.box_cat_two h3{
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    text-transform: capitalize;
    color: var(--theme-text);
    margin: 0;
    padding-top: 8px;
}
/* 
.payment_mode{
    padding-top: 20px;
} */

.pay_type{
    border-radius: var(--bd-radius-two);
    border:var(--theme-border);
    width: 100%;
    padding: 10px;
    margin-top: 16px;
}

.pay_type img{
    border: 0.5px solid #0000000a;
    border-radius: 17px;
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.pay_type .media h5{
    font-size: 14px;
    line-height: normal;
    color: var(--theme-text);
}

.pay_type .media p{
    font-size: 12px;
    line-height: normal;
    color:var(--gray);
    margin: 0;
}

input[type='checkbox'] {
    width: 19px;
    height: 19px;
    outline: var(--theme-border);
    margin-top: 6px !important;
    margin-right: 0;
}

.pay_type input[type='text']{
    height: 34px;
    margin-top: 10px;
    font-size: 14px;
    line-height: 26px;
    border-radius: 5px !important;
}
.input_length{
    padding-left:10px;
}
.pay_visit p{
    font-size: 16px;
    line-height: 22px;
    color: var(--theme-text);
    
    padding-top:20px ;
}

/* .pay_visit input[type='text']{
    width: 22px;
    height: 22px;
    border-radius: 5px;
    text-align: center;
} */
.pay_visit button{
    margin-top: 40px;
}

.optional_type{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--theme-text);
}
.pay_type .mt-0{
    margin-top: 0 !important;
}

/* .ruppy_sym{
    position: relative;
}

.ruppy_sym_new{
    position: absolute;
    top: 35px;
    right: 45px;
    z-index: 9999;
} */

.ruppy_sym_new_two{
    position: absolute;
    top: 35px;
    left: 25px;
    z-index: 9999;
    /* display: none; */
}

.ruppy_sym_new_three{
    top: 35px !important;
}


.ruppy_sym_new_two img{
    width: auto;
    height: auto;
    border: 0;
    border-radius: 0;
    margin: 0;
}


/* Location_page */

.location_sec iframe{
    width: 100%;
    height:calc(100vh - 75px);
}

/* Footer_first_second */
.foot_btn_second{
    background-color:var(--white);
    padding:10px 0 15px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 4px 0px 10px #00000080;
    bottom:0;
}
.foot_btn_second label{
    margin: 12px 0;
}

.close_btn{
    position: absolute;
    right: 6px;
    top: 2px;
    color: #000000;
    padding: 1px 6px;
    border-radius: 50px;
}

.close_btn_cust{
    position: relative;
    top: -28px;
    right: 13px;
    background: #00000061;
    padding: 2px 5px;
    color: #fff;
    border-radius: 50px;
    font-size: 12px;
}

.grv_img_up {
    position: relative;
    margin: 13px 0 0 15px;
    width: 20%;
}

.grv_img_up img{
    width: 76px;
    height: 76px;
    object-fit: cover;
}


.close_btn_grv{
    position: absolute;
    top: -10px;
    right: -7px;
    background: #00000061;
    padding: 2px 6px;
    color: #fff;
    border-radius: 50px;
    font-size: 12px;
    height: max-content;
}





/* success_section */
.success_box{
    padding-top: 120px;

}
.success_box .btn{
    margin-top: 316px;
}

.box-multiple{
    box-shadow: var(--theme-shadow);
    border-radius: var(--bd-radius);
    padding:23px  14px ;
}



.text-sucess{
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: var(--theme-text);
    padding-top: 12px;
}

.btn-under_input{
    background-color: var(--blue);
    border-color: var(--blue);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.doctor_details{
    box-shadow: var(--theme-shadow);
    border-radius: var(--bd-radius);
    margin-top: 16px;
    padding: 14px;
}
.doctor_details img{
    overflow: hidden;
}
.doctor_details .media-body{
    padding: 10px;
}
.doctor_details .media-body h5{
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--theme-text);
}
.doctor_details .media-body p{
    font-size: 12px;
    line-height: 19px;
    margin: 0;
    color: var(--gray);
}

.heath_detail .media img{
    border-radius: 50%;
    width: 63px;
    height: 63px;
    /* margin-right: 20px; */
}
.heath_detail .media h5{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--theme-text);
    margin: 0;
}

.heath_detail .media h5 span{
    color: var(--gray);
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    padding-left: 5px;
}
.heath_detail .media p{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--gray);
    margin: 0;
    padding-top: 5px;
}

.heath_detail .media ul{
    list-style: none;
    padding: 0;
}
.heath_detail .media ul li{
    display:inline-flex;
}
.heath_detail .media ul li a img{
    width: 36px;
    height: 36px;
}
.heath_detail_abt h4{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: var(--theme-text);
    padding-top: 15px;
}


.heath_detail_abt p{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: var(--theme-text);
    margin: 0;
}

.heath_detail_abt span{
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: var(--gray);
    position: relative;
    z-index: 999;
}

.heath_detail .btn-theme{
    padding: 10px 0;
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

.social_icon{
    width: 15px !important;
    height: 15px !important;
    object-fit: contain !important;
    border-radius: unset !important;

}

.booking_list .doctor_details:first-child{
    margin-top: 0;
}

.succes_time ul{
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    margin-top: 30px;
}

.succes_time ul li{
    display: inline-block;
    margin: 0 16px;
    text-align: center;
    padding: 0;
    width: 73px;
    height: 51px;
    background-color: var(--blue);
    border-radius: var(--bd-radius);
    font-size: 18px;
    line-height: 22px;
    color:var(--white);
    padding:4px 14px;
    margin: 0 10px;
}

.final_text{
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
}

.pay_description h5{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--theme-text);
    padding-right: 100px;
    margin: 0;
    padding:15px 100px 5px 0;
}

.pay_description p{
    font-size: 16px;
    line-height: 16px;
    color: var(--gray);
}

.pay_description h4{
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color:var(--theme-text);
}

.Pay_mode{
    font-size: 16px !important;
    line-height: 22px !important;
    margin: 0 !important;
    padding-right: 0 !important;
    padding-top: 5px !important;
}

.pay_mobile{
    font-size: 16px !important;
    line-height: 22px !important;
    margin: 0 !important;
    padding-right: 0 !important;
}

.btn-succes-theme{
    color: #fff;
    background: #30B246;
    border-radius: var(--bd-radius-two);
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 5px auto;
    text-align: center;
}

.contact_details ul{
   list-style: none;
   margin: 0;
   padding: 0;
   margin-top: 7px;
}

.contact_details ul li{
    display: inline-flex;
    padding-left:21px ;
 }

 .contact_details ul li:first-child{
    padding-left: 0;
 }

 .contact_details ul li a{
    box-shadow: var(--theme-shadow);
    border-radius: var(--bd-radius-two);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    color: var(--theme-text);
    padding: 5px;
 }


.proive_info ul{
    margin:0;
    list-style-type: circle;
}

.proive_info ul li{
    padding:0 14px;
    position: relative;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: var(--theme-text);
}

.proive_info ul li span{
    content:'';
    position: absolute;
    top: 9px;
    left: 0px;
    background: url(../images/done.png);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
}


.map_details iframe{
    width: 100%;
    height: 100px !important;
    border-radius: 8px;
    z-index:-11;
}

.map{
    position: relative !important;
    width: 100% !important;
    margin: 0 !important;
    margin-top: 6px !important;
}

.slot_scroll{
  overflow: auto;
  white-space: nowrap;
} 

.slot_scroll::-webkit-scrollbar {
    background-color: transparent;
}

.slot_scroll ul{
    list-style: none;
    padding: 0;
    margin-bottom: 5px;
}
.slot_scroll ul li{
    margin: 0;
    display: inline-block;
    white-space: break-spaces;
    text-align: center;
    padding-right: 10px;
}

.slot_scroll ul li a{
    border: var(--theme-border);
    background: var(--white);
    border-radius: var(--bd-radius-two);
    color: var(--theme-text);
    width: 40px;
    height: 40px;
    display: block;
    font-size: 12px;
    line-height: 12px;  
    padding: 5px;
    text-transform: uppercase;
    color: var(--theme-text);
    box-shadow: var(--theme-shadow);
}

.slot_scroll ul .active a{
    background: var(--blue);
    color: var(--white) !important;
    border-color: var(--blue);
}

.slot_scroll ul .not_fill a{
    background: #dadada4d;
    color: #43516033;
    opacity: .5;
}

.heath_success_box{
   
    box-shadow: var(--theme-shadow);
    border-radius: var(--bd-radius);
    padding: 20px;
    margin: 20px;
    text-align: left;
}

.heath_success_box h3{
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: var(--theme-text);
    padding-bottom: 10px;
    text-align: center;
}
.heath_success_box .media{
   margin: 30px;
}
.heath_success_box h5{
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: var(--theme-text);
    padding-top: 5px;
}
.heath_success_box p{
    padding: 0;
    margin: 0;
}
.heath_success_box img{
    width: 66px;
    height: 66px;
    border-radius: 50%;
    margin-right: 10px;
}

.heath_success_box ul{
    list-style: none;
    padding: 0;
    text-align: center;
}

.heath_success_box ul li{
    display: inline-block;
    background: var(--blue);
    border-radius: 15px;
    color: var(--white);
    width: 73px;
    height: 51px;
    font-size: 18px;
    line-height: 21px;
    padding:5px 15px;
    text-align: center;
    margin-right: 20px;
}

.profile_list{
    color: var(--theme-text);
    margin-bottom: 20px;
}

.profile_list .media img{
    width: 63px;
    height: 63px;
    border-radius: 50%;
    /* margin-right: 20px; */
    object-fit: cover;
    object-position: top;
}

.profile_list .media .media-body h5{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    padding: 5px 0;
    color: var(--theme-text-two);
}
.profile_list .media .media-body p{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: var(--theme-text-two);
}

.profile_list_menu{
    border: var(--theme-border);
    border-radius: var(--bd-radius);
    margin-top: 16px;
    padding: 12px 15px;
}
 
.profile_list_menu .media img{
    margin-right: 20px;
    margin-left: 10px;
    width: 22px;
    height: 22px;

}

.profile_list_menu h5{
    font-weight: 400;
    font-size: 20px;
    line-height: normal;
    color: var(--theme-text-two);
    margin: 0;
}

.user_book_info{
    box-shadow: var(--theme-shadow);
    border-radius: var(--bd-radius);
    margin-top: 16px;
    padding: 12px;
}
.user_book_info:first-child{
    margin-top: 0;
}
.user_book_info .media img{
    width: 63px;
    height: 63px;
    /* margin-right: 18px; */
    border-radius: var(--bd-radius);
}

.user_book_info .media h5{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color:var(--theme-text);
    margin: 0;
    padding-top: 5px
}

.patient_sec{
    margin-top: 40px;
}

.special_text{
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: var(--theme-text-two);
    margin: 0;
    padding-top:23px;
}

.user_book_info .media p{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color:var(--gray);
    margin: 0;
}

.badge{
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    border-radius: 4px;
    padding: 3px 10px 3px 11px;
}
.badge-primary{
    color:var(--white);
    background: var(--blue);
}
.dot {
    height: 6px;
    width: 6px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: -1px;
    right: 2px;
}

.user_detail .media img{
    border-radius: 50%;
}

.book-information {
    float: right;
}
.book-information a img{
    width: 24px !important;
    height: 24px !important;
}

.book_info_date {
    border-radius: var(--bd-radius);
    border: var(--theme-border);
    padding:12px 15px;
    margin-top:16px ;
}

.book_info_date p{
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: var(--theme-text);
}

.book_info_date p span{
    float: right;
    font-weight: 400;
}

.payment_proof{
    margin-top: 16px;
}

.payment_proof img{
    border: 1px solid gray;
    border-radius: var(--bd-radius-two);
    width: 100%;
}

.payment_proof h3{
    color: var(--theme-text);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.btn_sec_theme{
    border-radius: var(--bd-radius);
    background: var(--blue);
    color: var(--white) !important;
    padding: 9px 10px;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
}

.proof_btn_het{
    margin-top: 50px;
}

.patient_book img{
    width: 56px !important;
    height: 56px !important;
}

.patient_book .media h5{
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
} 
.patient_book .media p{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
} 
.slot_scroll h4{
    color: var(--theme-text);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}
                   
.rating_sys h5{
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color:var(--theme-text);
    margin: 0;
    padding-bottom: 12px;
}

 textarea{
    border-radius: var(--bd-radius-two) !important;
    border: var(--theme-form-bd) !important;
}

.rating_sys .btn-theme{
    margin-top: 50px;
}

.star_view img{
    width: 16px !important;
    height: 16px !important;
    margin: 0 !important;
}

.star_number{
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: var(--blue) !important;
    vertical-align: text-bottom !important;
    padding-left: 7px;
    position: relative;
    top: 1px;
}
.btn-review{
    background-color: var(--blue);
    color: var(--white);
    padding:6px 8px;
    border-radius: var(--bd-radius);
    border: var(--theme-border);
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
}

.person_review img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 16px;

}

.person_review h5{
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--theme-text);
}

.person_review p{
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--gray);
}

.person_review .btn-link{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--blue);
    text-decoration: underline;
}

.btn-review_2{
    background: var(--blue);
    box-shadow: 0px 2px 5px #00000040;
    border-radius: 12px;
    padding: 7px 12px;
    color: var(--white);
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}

.btn-review_2 img{
    width: 12px !important;
    height: 12px !important;
    margin: 0 !important;
}

.slider_review{
    border: 1px solid #C3F0FF;
    border-radius: 12px;
    padding: 10px;
    color: var(--theme-text);
    font-weight: 400;
}

.slider_review h5{
    font-size: 16px;
    line-height: 22px;
    margin: 0;
}

.slider_review p{
    font-size: 15px;
    line-height: 18px;
    margin-top: 8px;
    margin-bottom: 0;
}

.slider_review .star_number{
    top: 2px;
}

.carousel-indicators li{
    background-color:#E1EAF4;
    opacity: 1;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.carousel-indicators .active{
    background-color: var(--blue);
}

.carousel-indicators{
    position: unset;
    margin-bottom: 0;
}

.virtual_box {
    border-radius: var(--bd-radius);
    border: var(--theme-border);
    padding: 18px;
    margin-top: 18px;
}
.virtual_box h4{
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--theme-text);
    margin: 0;
}

.virtual_box p{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: var(--theme-text);
    margin: 0;
    padding-top:3px;
}

.virtual_box.active{
    background-color: #1383F433 !important;
}

/* loader */
.preloader {
    position: absolute;
    top: 0;
    left: 0;
    bottom:0;
    right:0;
    width: 100%;
    height: 100vh;
    z-index: 99999999;
    background-image: url(../images/Rhombus.gif); /* your icon gif file path */
    background-repeat: no-repeat;
    background-color: #FFF;
    background-position: center;
    background-size: 50%;
}

#stop-scrolling {
    height: 100% !important;
    overflow: hidden !important;
}

/* loader end*/

.select_app_time{
    border: var(--theme-form-bd);
    font-weight: 400;
    font-size: 12px;
    line-height: unset;
    color: var(--theme-text);
    padding: 9px;
    border-radius: 5px;
    width: 15%;
    margin-left: 4.2%;
    text-align: center;
    height:50px;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.start_time{
    width: 20%;
}

.start_time input[type="time"]{
    margin-left: 0 !important;
}

.time_con_btn{
    width: 10%;
}


.close_time{
    border-radius: 50%;
    border: 0;
    background: var(--blue);
    width: 32px;   
    height: 32px;
}

.close_time a{
    display: block;
    padding: 4px 2px;
}

.time_heading{
    border: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: var(--theme-text);
}

.btn-under_input{
    background: transparent;
    color:#396EA0 ;
    border:var(--theme-form-bd);
    border-radius: var(--bd-radius-two) !important;
    border-right:0 ;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
    padding-right: 0;
}

.new_bdr{
    border-left: 0 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.form-control:focus{
    border: var(--theme-form-bd);
}

.box_sd_none{
    box-shadow: none;
}

.icon_syml{
    width: 20px !important;
    height: 20px !important;
    border-radius: unset;
    vertical-align: sub;
}

.icon_syml_call{
    width: 20px !important;
    height: 16px !important;
    border-radius: unset;
    vertical-align: sub;
}

.fee_charge{
    margin-top: 16px;
}

.fee_charge .form-group{
    margin-bottom: 16px;
}

.border_diveder{
    border: 3px solid #1383F4;
    width: 100%;
    margin-top: 16px;
    border-radius: 4px;
}

.review_list_sec .doctor_details:first-child{
    margin-top: 0;
}

.avatar-upload {
    position: relative;
    max-width: 100%;
    margin: 22px auto;
}
    .avatar-edit {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(100%, 45%);
        height: 20px;
    }
    .avatar-edit  input {
            display: none;
    }
    .avatar-edit label {
                display: inline-block;
                width: 20px;
                height:20px;
                margin-bottom: 0;
                border-radius: 100%;
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;
                background: #00000061;
    }
    .avatar-edit label:after {
        content: "";
        color: #757575;
        position: absolute;
        top: 4px;
        left: 4px;
        right: 0;
        text-align: center;
        margin: auto;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAALCAYAAABLcGxfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACtSURBVHgBjZFhDcIwEIV7pP+ZhEqohDlgDjYHaMDBHBAUTAKggDoACUXB8Zq8wq0khC95Wbt799pLxRlUtccnuDVJRJJrgTnohztVidW3oXHB+sZ/GTpRlQWec/GW5Fm/aU+oHD0THiatJG+5Fmhnas/SkIzhCo1Qz/0FmqE6Q/IsjiYlmHXH+vAe2q0ZeKVsho/W4JuGjqcduN+79l0w+aT/MwmbItN/kcuLvwCCWqSOsYHL9gAAAABJRU5ErkJggg==);
        width: 100%;
        height: 10px;
        background-repeat: no-repeat;
        background-size: 60%;
    }

    .btn_close_profile{
        position: absolute;
        top: -45px;
        left: -45px;
        background: #00000061;
        padding: 0px 5px;
        color: #fff;
        border-radius: 50px;
        display: none;
    }
        
    .avatar-preview {
        width: 76px;
        height: 76px;
        position: relative;
        border-radius: 100%;
        box-shadow: 0px 2px 4px 0px #0000001a;
        left: 50%;
        transform: translate(-50%, 0px);
        position: relative;
    }
        .avatar-preview  > div {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top;
        }

        .pd-t-8{
            padding-top: 8px !important;
        }
        .pd-t-5{
            padding-top: 5px !important;
        }
        .app_done{
            color: #7c7c7c;
            font-weight: 500;
            font-size: 16px;
            line-height: normal;
            text-align: center;
        }

        .write_btn{
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: var(--blue);
            float: right;
            text-decoration: underline;
        }

#day{
    background: #fff !important;
    -webkit-appearance: none; 
    appearance: none !important;
    text-align: center;
}

#ends_at{
    background: #fff;
    -webkit-appearance: none; 
    appearance: none !important;
    text-align: center;
    width: 100% !important;
    margin-left: 5px;
}
#starts_at{
    background: #fff;
    -webkit-appearance: none; 
    appearance: none !important;
    text-align: center;
    width: 100% !important;
    margin-left: 9px;
}

/* input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}
input[type="time"], input[type="time"]::-webkit-date-and-time-value{
    -webkit-appearance: none !important; 
} */

:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.gps_info p{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 20px;
}

.gps_green{
    padding: 2px 5px;
    background: #30B346;
    color: #30B246;
    border-radius: 50px;
    font-size: 6px;
    vertical-align: bottom;
}

.gps_red{
    background: #C50808;
    color: #C50808;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e8f0fe;
    opacity: 1;
}

.btn-book_suss{
    width: 85.5%;
}

.btn_v_pro{
    width: 48%;
}

.service_title{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: var(--gray);
    padding-left: 5px;

}

.btn-danger{
    background-color: #d9534f !important;
}

.btn-success{
    background-color: #449d44 !important;
}
.success_box_review{
    padding-top: 237px;
}

.success_box_review p{
    padding-top: 20px;
}

.Foot_btn_grievance {
    position: fixed;
    bottom: 10px;
    width: 88%;
}

.react-datepicker__input-container>input[type='text']{
    border: 0;
    font-size: 12px;
    padding-left: 6px;
    line-height: normal;
}

.react-datepicker__header--time{
    display: none;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
    display: none;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    padding: 8px 3px !important ;
    width: min-content;
    font-size: 12px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 60px !important;
    margin: 0 !important;
}
.react-datepicker__time-container {
    float: right;
    width: 60px;
}

.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
    border-radius: 0;
    overflow: hidden;
    border: 1px solid black;
}

.react-datepicker__time-container{
    width: 60px !important;
}

.react-datepicker--time-only .react-datepicker__time {
    border-radius: 0 !important;
}

.react-datepicker-popper{
    top: -20px;
    left: -1px;
}

.select_days{
    width:50px;
    padding: 5px;
    border:none
}

.list_slot{
    color: var(--white);
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    padding: 0px;
    display: block;
    width: 100%;
}

.booked_slot{
    color: var(--white);
    text-transform: uppercase;
    padding: 0px;
    display: block;
    width: 100%;
}

.booking_mobile{
    box-shadow: var(--theme-shadow);
    border-radius: var(--bd-radius-two);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    color: var(--theme-text);
    padding: 5px;
}

.none_outline:focus {
    outline: none;
    /* outline: 5px auto -webkit-focus-ring-color; */
}


/* The container must be positioned relative: */
.custom-select {
    position: relative;
    font-family: 'Calibri';
    border: none !important;
    padding: 3px;
    font-size: 12px;
    color: #000;
    font-weight: 400;
  }

  .custom-select option {
    position: relative;
    font-family: 'Calibri';
    border: none !important;
    padding: 10px;
    font-size: 12px;
  }

  .custom-select option.firstchild {
    margin-top: 5px !important;
  }
  
  .custom-select select {
    display: none; /*hide original SELECT element: */
  }
  
  .select-selected {
    background-color: DodgerBlue;
  }
  
  /* Style the arrow inside the select element: */
  .select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: none;
  }
  
  /* Point the arrow upwards when the select box is open (active): */
  .select-selected.select-arrow-active:after {
    border:none;
    top: 7px;
  }
  
  /* style the items (options), including the selected item: */
  .select-items div,.select-selected {
    color: #ffffff;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
  }
  
  /* Style items (options): */
  .select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
  }
  
  /* Hide the items when the select box is closed: */
  .select-hide {
    display: none;
  }
  
  .select-items div:hover, .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
  }

  option{
      padding: 8px 3px !important;
      font-size: 12px;
      margin-top: 3px;
      width: 60px;
  }
  
  .d-flex-cust{
      display: flex;
  }

  .bell_icon{
      position: relative;
  }
  .bell_count{
    position: absolute;
    color: white;
    background-color: red;
    font-size: 10px;
    border-radius: 50%;
    line-height: 16px;
    right: 12px;
    top: -2px;
    width: 16px;
    height: 16px;
    text-align: center;
  }
  .no_cnt_data{
      height: 80vh; 
      display: grid;
      place-items: center;
      padding: 0 !important;
  }

  #seeAllBooking a:first-child{
    line-height: 16px;
    padding: 3px;
}

#patient_date_slot li{
    vertical-align: bottom;
}

.css-1okebmr-indicatorSeparator{
    display: none !important;
}
.css-1hb7zxy-IndicatorsContainer{
    display: none !important;
}

.css-2b097c-container{
    width: 100%;
}

.css-g1d714-ValueContainer{
    padding: 1px 2px !important;
}

.css-1uccc91-singleValue{
    max-width: 100% !important;
}

.css-1wa3eu0-placeholder{
    display: none !important;
}

.css-yk16xz-control{
    height: 48px !important;
    border-color: transparent !important;
}

.select_app_time:first-child .css-1uccc91-singleValue{
    left:22% !important;
}

.react-datepicker__input-container > input[type='text'] {
    border: 0;
    font-size: 12px;
    width: 52px;
    padding-left: 6px;
    line-height: normal;
}

textarea.form-control {
    padding:6px 20px;
    color:#8E8E8E !important;
}

.pay_type .col-11 {
    flex: 0 0 90%;
    max-width: 90%;
}

.container{
    padding:0 23px;
}

.gmnoprint{
    visibility: hidden;
}

.contact_details ul li a:focus {
    box-shadow: var(--theme-shadow);
    border-radius: var(--bd-radius-two);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    color: var(--theme-text);
    padding: 5px;
}

.read-all-btn{
    padding: 0;
    font-size: 16px;
    color: var(--blue);
    /* color: var(--header-color); */
}

.text-red{
    color: red !important;
}
.special_text_disclaimer{
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: red !important;
    margin: 0;
    padding-top:23px;
}


input[type="time"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
input[type="time"] {
    -webkit-appearance: none !important; 
  }
input[type="time"]::-webkit-clear-button {
    -webkit-appearance: none !important;
}

.view_all_btn{
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: var(--blue);
    float: right;
    text-decoration: none;
    padding-top: 3px;
}


.new_lable_final{
    font-size: 14px !important;
}

.new_lable_radio{
    font-size: 16px !important;
}

.red-input {
    accent-color: #1383F4 !important;
}

.tandc_label{
    font-size: 16px;
    color: var(--theme-text);
    margin: 0;
    padding-top: 5px;
}
.mt-20{
    margin-top: 20px;
}

.tandc_label a{
    color: var(--blue);
}


.lang_change label{
    color: var(--header-color) !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 24px !important;
}

.lang_change .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #fff;
}

.lang_change .custom-control-label::before{
    border-color: var(--blue);
}
.lang_change .custom-control-label::after {
    background-color:var(--blue);
}

.lang_change .custom-control-input:checked ~ .custom-control-label::after{
    background-color: var(--blue);
}

.bott_fix{
    width: 100%;
    position: fixed;
    bottom: 10px;
}

.diss_box .alert-danger {
    font-size: 14px;
    line-height: normal;
    padding: 10px 18px;
}

.alert{
    border-radius: var(--bd-radius);
}

.tandc_section p{
    line-height: normal;
    margin-bottom: 10px;
}

.tandc_section h3{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--header-color);
    margin: 20px 0;
}

.language_btn ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.language_btn ul li{
    display: grid;
    height: 30px;
    background: #eaedf2;
    padding: 5px;
}

.language_btn ul li a{
    color: var(--header-color) !important;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: normal;
}

.language_btn ul li a.hind{
    font-size: 16px;
}

.language_btn ul li.active{
    background-color: var(--blue);
    color: #fff ;
}

.language_btn ul li.active a{
    color: #fff !important;
}

.provider_detail_sec p{
    font-size: 14px !important;
}

.info_icon{
    width: 15px !important;
    height: auto !important;
    vertical-align: bottom !important;
}

.tooltip_new {
    position: relative;
    display: inline-block;
  }
  
  .tooltip_new .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #1383F4;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 7px;
    position: absolute;
    z-index: 1;
    bottom: 160%;
    left: 50%;
    margin-left: -100px;
    font-size: 12px;
  }
  
  .tooltip_new .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #1383F4 transparent transparent transparent;
  }
  
  .tooltip_new:hover .tooltiptext {
    visibility: visible;
  }

  .pay_info_text{
    font-size: 14px !important;
    line-height: 16px !important;
    color: #1383F4 !important;
    margin-top: 10px !important;
  }

  .splash_sec{
    width: 100%;
    height: 100vh;
    z-index: 99999999;
    background-image: url(./../images/Splash.jpg); /* your icon gif file path */
    background-repeat: no-repeat;
    background-color: #FFF;
    background-position: center;
    background-size: 102%;
  }

  .splash_logo{
      padding-top: 50px;
  }

  .splash_text{
    display: grid;
    place-items: center;
    color: #fff;
    height: 45vh;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .badge-warning{
      color: #fff !important;
  }

  .splash_text h3{
      padding-top: 120px;
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      text-transform: uppercase;
  }

  .splash_text p{
      font-weight: 700;
  }


  /* product page */
  .product-list {
    padding: 20px;
}

.product-card {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin: 10px 0;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.product-image {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 50%;
    overflow: hidden;
}

.product-image img {
    width: 100%;
    height: auto;
}

.placeholder {
    font-size: 40px; /* Adjust size of placeholder icon */
}

.product-details {
    flex-grow: 1;
}

.product-details h2 {
    margin: 0;
    font-size: 20px;
    color: #333;
}

.product-details p {
    margin: 5px 0;
    color: #555;
}

.warning {
    color: red;
    font-weight: bold;
}

.minus-btn {
    cursor: pointer;
}
.plus-btn {        
    cursor: pointer;
}
    /*
.minus-btn::before {
    content: "\2212"; 
    font-size: 18px;
}

.plus-btn::before {
    content: "\002B"; 
    font-size: 18px;
} */


.count-btn {
    width: 25px;
    height: 25px;
    justify-items: legacy;
    padding: 0;
    background: #1383F4;
    margin: 0;
    border: none;
    color: white;
    border-radius: 50%;
    font-weight: bold;
    font-size: 15px;
    line-height: unset;
    padding-bottom: 5px;
}

.count-btn-disabled {
    background-color: #1383F480;
    width: 25px;
    height: 25px;
    justify-items: legacy;
    padding: 0;
    cursor: not-allowed;    
    margin: 0;
    border: none;
    color: white;
    border-radius: 50%;
    font-weight: bold;
    font-size: 15px;
    line-height: unset;
    padding-bottom: 5px;    
}

.text-color {
    color: #435160 !important;
}

.img-listing {
    max-width: 100px !important;
    border-radius: 5% !important;
    object-fit: cover !important;
    background-color: transparent !important;
}