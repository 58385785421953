@media screen and (min-width:1300px) {
    .Foot_btn_grievance{
        width: 56%;
    }
}

@media screen and (max-width:388px) {
    .contact_details ul li {
        padding-left: 8px;
    }
    .proive_info ul li {
        padding: 0 12px;
    }

}

@media screen and (max-width:381px){
    .payment_mode .col-1 {
        flex: 0 0 8.333333%;
        max-width: 10%;
    }

    .payment_mode .col-11 {
        flex: 0 0 87%;
        max-width: 87%;
    }
}

@media screen and (max-width:370px){
    .heath_detail .media img{
        width: 50px;
        height: 50px;
    }
    .contact_details ul li {
        padding-left: 14px;
    }
    .contact_details ul li {
        padding-left: 10px;
    }
}